import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Showcase = () => {
  const [data, setData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const builder = ImageUrlBuilder(sanityClient);
  const urlFor = (source) => {
    return builder.image(source);
  };

  const editUrlString = (urlString) => {
    let strUrl = urlString.replace(/file-/g, "");
    strUrl = strUrl.replace("-", ".");

    return `https://cdn.sanity.io/files/nebzpyuk/production/${strUrl}`;
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'showcase'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result.libations);
      setSliderImages(result.media);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMenuData = async () => {
    try {
      const query = `*[_type == 'header'][0]`;
      const result = await sanityClient.fetch(query);
      setMenuData(result.navigation[1]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMenuData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % sliderImages.length
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [sliderImages]);

  const currentImage = sliderImages[currentImageIndex];

  return (
    <>
      <section className="showcase">
        <div className="slider">
          {currentImage && (
            <img
              src={urlFor(currentImage.asset._ref).url()}
              alt="Slider Image"
            />
          )}
        </div>
        <article className="libations">
          <div className="header">
            {data && data.header && (
              <>
                <h1>{data.header.title}</h1>
                <p>{data.header.description}</p>
              </>
            )}
          </div>
          <div className="still-life">
            {data && data.stillLife && (
              <>
                <div className="title-container">
                  <h1>{data.stillLife.title}</h1>
                  <h1>{data.stillLife.title}</h1>
                </div>
                <h2>{data.stillLife.heading}</h2>
                <p>{data.stillLife.subheading}</p>
              </>
            )}
          </div>
          <div className="imagined-life">
            {data && data.imaginedLife && (
              <>
                <div className="title-container">
                  <h1>{data.imaginedLife.title}</h1>
                  <h1>{data.imaginedLife.title}</h1>
                </div>
                <h2>{data.imaginedLife.heading}</h2>
                <p>{data.imaginedLife.subheading}</p>
              </>
            )}
          </div>
          <div className="menu-links">
            {menuData.dropdown !== undefined &&
              menuData.dropdown.map((dropdownItem) => (
                <>
                  <li key={dropdownItem._key}>
                    <a
                      target="_blank"
                      href={
                        dropdownItem.file !== undefined
                          ? editUrlString(dropdownItem.file.asset._ref)
                          : "https://www.icegif.com/wp-content/uploads/2023/01/icegif-162.gif"
                      }
                    >
                      {dropdownItem.title}
                    </a>
                  </li>
                  <span> | </span>
                </>
              ))}
          </div>
        </article>
      </section>
    </>
  );
};
