import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

import LOGO from "../../Assets/logo.svg";

export const AsideMenu = () => {
  const [data, setData] = useState([]);
  const [openMenuDrawer, setOpenMenuDrawer] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const builder = ImageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const editUrlString = (urlString) => {
    let strUrl = urlString.replace(/file-/g, "");
    strUrl = strUrl.replace("-", ".");

    return `https://cdn.sanity.io/files/nebzpyuk/production/${strUrl}`;
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'aside'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = (itemId) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <aside className="aside-menu">
        <h1>
          <a href="/">Slice Of Life</a>
        </h1>
        <div
          className="menu-line"
          onClick={() => {
            setOpenMenuDrawer(!openMenuDrawer);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="socila-media-container">
          {data &&
            data.socialMedia &&
            data.socialMedia.map((item) => {
              return (
                <a key={item._key} href={item.url} target="_blank">
                  <img src={urlFor(item.icon.asset._ref).url()} />
                </a>
              );
            })}
        </div>
        <div className={`menu-drawer ${openMenuDrawer ? "show" : ""}`}>
          <div id="aside-menu-drawer-overlay" />
          <a href="/">
            <img src={LOGO} />
          </a>
          <ul>
            {data &&
              data.navigation &&
              data.navigation.map((item) => {
                const isOpen = openDropdowns[item._key];

                return (
                  <div className="navigation-cells">
                    <li key={item._key}>
                      {item.url ? (
                        <a href={item.url}>{item.title}</a>
                      ) : (
                        <div>
                          <span onClick={() => toggleDropdown(item._key)}>
                            {item.title}
                          </span>
                          {item.dropdown && isOpen && (
                            <ul className="dropdown">
                              {item.dropdown.map((dropdownItem) => (
                                <li key={dropdownItem._key}>
                                  <a
                                    target="_blank"
                                    href={
                                      dropdownItem.file !== undefined
                                        ? editUrlString(
                                            dropdownItem.file.asset._ref
                                          )
                                        : "https://www.icegif.com/wp-content/uploads/2023/01/icegif-162.gif"
                                    }
                                  >
                                    {dropdownItem.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      </aside>
    </>
  );
};
