import { Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  MeshReflectorMaterial,
  CameraControls,
} from "@react-three/drei";
import {
  EffectComposer,
  Bloom,
  DepthOfField,
} from "@react-three/postprocessing";
import * as THREE from "three";

import MODEL from "../../Assets/model.glb";

import "./index.css";

const bloomColor = new THREE.Color("#F5EBA0");
bloomColor.multiplyScalar(2);

const Model = () => {
  const { scene } = useGLTF(MODEL);
  const leftPipes = scene.children[0];
  const rightPipes = scene.children[1];

  leftPipes.children.forEach((child) => {
    if (
      child.name === "Pipe03" ||
      child.name === "Pipe06" ||
      child.name === "Pipe09" ||
      child.name === "Pipe10" ||
      child.name === "Pipe14" ||
      child.name === "Pipe19" ||
      child.name === "Pipe27" ||
      child.name === "Pipe29"
    ) {
      child.visible = false;
    }
  });

  rightPipes.children.forEach((child) => {
    if (
      child.name === "Pipe032" ||
      child.name === "Pipe035" ||
      child.name === "Pipe038" ||
      child.name === "Pipe039" ||
      child.name === "Pipe043" ||
      child.name === "Pipe046" ||
      child.name === "Pipe048" ||
      child.name === "Pipe056" ||
      child.name === "Pipe058"
    ) {
      child.visible = false;
    }
  });

  //   removeMesh.visible = false;

  scene.rotation.y = 1.55;
  scene.position.x = -0.9;
  scene.position.y = -0.9;
  scene.position.z = 1.8;

  return <primitive object={scene} scale={1} />;
};

const LightTubes = ({ position, color }) => {
  return (
    <mesh position={position} rotation={[1.575, 0, 0]}>
      <cylinderGeometry args={[0.005, 0.005, 4.5, 32]} />{" "}
      {/* Skinny cylinder */}
      <meshStandardMaterial emissive={color} color={color} />
    </mesh>
  );
};

export const Entry = () => {
  const controls = useRef();
  const depthOfFieldRef = useRef();

  const CameraMovement = () => {
    useFrame(() => {
      if (controls.current) {
        controls.current._removeAllEventListeners();
        controls.current.dolly(0.001);
        controls.current.smoothTime = 1.5;
        console.log(controls.current);

        if (controls.current._camera.position.z <= 3.8) {
          if (
            depthOfFieldRef.current &&
            depthOfFieldRef.current.bokehScale < 15
          ) {
            depthOfFieldRef.current.bokehScale += 0.05;
          }
        }
      }
    });

    return <CameraControls ref={controls} />;
  };

  return (
    <>
      <div className={`canvas`}>
        <Canvas
          camera={{ fov: 70, near: 0.1, far: 10000, position: [0.5, 0, 4] }}
        >
          <CameraMovement />
          <ambientLight color={"#3b130b"} intensity={0.3} />
          <directionalLight
            color="#fff"
            intensity={0.3}
            position={[0, 10, 10]}
          />
          <directionalLight
            color="#fff"
            intensity={0.2}
            position={[-0.5, 10, 10]}
          />
          <Suspense fallback={null}>
            <Model />
            <group name="light-tubes">
              {/*ceiling light tubes */}
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.4, 0.75, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.3, 0.75, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.2, 0.75, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.55, 0.75, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.45, 0.75, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.35, 0.75, 1.9]}
              />

              {/*left side light tubes */}
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.55, -0.3, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.55, -0.6, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.55, 0, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.55, 0.3, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[-0.55, 0.6, 1.9]}
              />

              {/*right side light tubes */}
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.67, -0.3, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.67, -0.6, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.67, 0, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.67, 0.3, 1.9]}
              />
              <LightTubes
                emissive={bloomColor}
                color={bloomColor}
                position={[0.67, 0.6, 1.9]}
              />
            </group>

            {/*floor reflector */}
            <group name="reflectors">
              <mesh
                position-y={-0.7}
                position-z={2.2}
                rotation-x={-Math.PI / 2}
              >
                <planeBufferGeometry args={[2, 6]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={1}
                  roughness={0}
                  depthScale={1}
                  opacity={1}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1}
                  metalness={1}
                  color={"#222"}
                />
              </mesh>
              {/*left reflector 1 */}
              <mesh
                position-y={0}
                position-z={3}
                position-x={-0.62}
                rotation-y={1.545}
              >
                <planeBufferGeometry args={[1.05, 2]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={50}
                  roughness={1}
                  depthScale={10}
                  opacity={1}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1}
                  metalness={1}
                  color={"#fff"}
                />
              </mesh>
              {/*left reflector 2 */}
              <mesh
                position-y={0}
                position-z={0.75}
                position-x={-0.575}
                rotation-y={1.56}
              >
                <planeBufferGeometry args={[1.05, 2]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={50}
                  roughness={1}
                  depthScale={10}
                  opacity={1}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1}
                  metalness={1}
                  color={"#fff"}
                />
              </mesh>
              {/*right reflector 1 */}
              <mesh
                position-y={0}
                position-z={3}
                position-x={0.67}
                rotation-y={-1.58}
              >
                <planeBufferGeometry args={[1.05, 2]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={50}
                  roughness={1}
                  depthScale={10}
                  opacity={1}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1}
                  metalness={1}
                  color={"#fff"}
                />
              </mesh>
              {/*right reflector 2 */}
              <mesh
                position-y={0}
                position-z={0.75}
                position-x={0.71}
                rotation-y={-1.58}
              >
                <planeBufferGeometry args={[1.05, 2]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={50}
                  roughness={1}
                  depthScale={10}
                  opacity={1}
                  minDepthThreshold={0.4}
                  maxDepthThreshold={1}
                  metalness={1}
                  color={"#fff"}
                />
              </mesh>
              {/*ceiling reflector */}
              <mesh position-y={0.75} position-z={2.2} rotation-x={Math.PI / 2}>
                <planeBufferGeometry args={[2, 6]} />
                <MeshReflectorMaterial
                  blur={[0, 0]}
                  resolution={1000}
                  mixBlur={1}
                  mixStrength={50}
                  roughness={0}
                  depthScale={1}
                  opacity={1}
                  minDepthThreshold={0}
                  maxDepthThreshold={1}
                  metalness={0}
                  color={"#fff"}
                />
              </mesh>
            </group>
          </Suspense>
          <EffectComposer>
            <Bloom mipmapBlur intensity={1} />
            <DepthOfField
              focusDistance={0.02}
              focalLength={0.02}
              bokehScale={0}
              height={480}
              ref={depthOfFieldRef}
            />
          </EffectComposer>
        </Canvas>
      </div>
    </>
  );
};
