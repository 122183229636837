import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Footer = () => {
  const [data, setData] = useState([]);

  const builder = ImageUrlBuilder(sanityClient);
  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'footer'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(data.storeHours);

  return (
    <>
      <footer className="footer">
        <div className="top-footer">
          <div className="footer-information">
            {data && data.contact && (
              <div className="contact-block">
                <h1>{data.contact.title}</h1>
                <ul>
                  <li>
                    <a href={`mailto:${data.contact.email}`}>
                      {data.contact.email}
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${data.contact.phone}`}>
                      {`+1 (${String(data.contact.phone).slice(0, 3)})-${String(
                        data.contact.phone
                      ).slice(3, 6)}-${String(data.contact.phone).slice(6)}`}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://www.google.com/maps/place/${data.contact.address.replace(
                        / /g,
                        "+"
                      )}`}
                      target="_blank"
                    >
                      {data.contact.address}
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <div className="hours-block">
              <h1>Our Hours</h1>
              <table>
                {data &&
                  data.storeHours &&
                  data.storeHours.map((set, index) => {
                    return (
                      <tr key={index}>
                        <td>{set.days}</td>
                        <td>{set.hours}</td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
          {data && data.logo && (
            <div className="logo-container">
              <img src={urlFor(data.logo.asset._ref).url()} />
            </div>
          )}
        </div>
        <div className="bottom-footer">
          {data && data.copyright && (
            <ul>
              {data &&
                data.copyright &&
                data.copyright.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              <li>
                <a href="https://www.hexstudio.io" target="_blank">
                  Website by hexstudio.io
                </a>
              </li>
            </ul>
          )}
        </div>
      </footer>
    </>
  );
};
