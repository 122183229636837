import { useEffect, useState } from "react";
import sanityClient from "../../client";

import "./index.css";

export const Header = () => {
  const [data, setData] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const fetchData = async () => {
    try {
      const query = `*[_type == 'header'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDropdown = (itemId) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const editUrlString = (urlString) => {
    let strUrl = urlString.replace(/file-/g, "");
    strUrl = strUrl.replace("-", ".");

    return `https://cdn.sanity.io/files/nebzpyuk/production/${strUrl}`;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <header>
        <nav className="navigation">
          <ul>
            {data &&
              data.navigation &&
              data.navigation.map((item) => {
                const isOpen = openDropdowns[item._key];

                return (
                  <div className="navigation-cells">
                    <li key={item._key}>
                      {item.url ? (
                        <a
                          href={item.url}
                          target={
                            item.title === "reservations" ? "_blank" : "_self"
                          }
                        >
                          {item.title}
                        </a>
                      ) : (
                        <div>
                          <span onClick={() => toggleDropdown(item._key)}>
                            {item.title}
                          </span>
                          {item.dropdown && isOpen && (
                            <ul className="dropdown">
                              {item.dropdown.map((dropdownItem) => (
                                <li key={dropdownItem._key}>
                                  <a
                                    target="_blank"
                                    href={
                                      dropdownItem.file !== undefined
                                        ? editUrlString(
                                            dropdownItem.file.asset._ref
                                          )
                                        : "https://www.icegif.com/wp-content/uploads/2023/01/icegif-162.gif"
                                    }
                                  >
                                    {dropdownItem.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </li>
                  </div>
                );
              })}
          </ul>
        </nav>
      </header>
    </>
  );
};
