import { useEffect, useState } from "react";
import sanityClient from "../../client";

import "./index.css";

export const AnnouncementBar = () => {
  const [data, setData] = useState([]);
  const [width] = useState(window.innerWidth);

  const fetchData = async () => {
    try {
      const query = `*[_type == 'announcement'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);

  //   const month = date.toLocaleString("default", { month: "long" });
  //   const day = date.getDate();
  //   const year = date.getFullYear();

  //   return `${month} ${day + 1}, ${year}`;
  // };

  // const formatDateTime = (dateTimeString) => {
  //   const dateTime = new Date(dateTimeString);

  //   const month = dateTime.toLocaleString("default", { month: "long" });
  //   const day = dateTime.getDate();
  //   const year = dateTime.getFullYear();

  //   return `${month} ${day + 1}, ${year}`;
  // };

  return (
    <>
      <div className="announcement-bar">
        {data && data.announcement && (
          <marquee
            width="100%"
            vspace={width >= 768 ? "20" : "0"}
            scrollamount="10"
            direction="left"
          >
            <h1>
              <span>{data.announcement.eventText}</span>
              <span>{data.announcement.eventText}</span>
              <span>{data.announcement.eventText}</span>
              <span>{data.announcement.eventText}</span>
              <span>{data.announcement.eventText}</span>
            </h1>
          </marquee>
        )}
      </div>
    </>
  );
};
