import { useEffect, useState, useRef } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";
import emailjs from "@emailjs/browser";

import "./index.css";

export const Contact = () => {
  const formRef = useRef();
  const [width] = useState(window.innerWidth);

  const [data, setData] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [sendSuccess, setSendSuccess] = useState("");
  const [formData, setFormData] = useState({
    from_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then((response) => {
        setFormData({
          from_name: "",
          email: "",
          subject: "",
          message: "",
        });
        setSendSuccess("success");
      })
      .catch((error) => {
        setSendSuccess("error");
        console.log(error);
      });
  };

  const builder = ImageUrlBuilder(sanityClient);
  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'contact'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
      setSliderImages(result.media);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % sliderImages.length
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [sliderImages]);

  const currentImage = sliderImages[currentImageIndex];

  return (
    <>
      <section className="contact">
        <div className="contact-form-container">
          <div className="contact-header">
            {data && data.form && (
              <>
                <h1>{data.form.title}</h1>
                <p>{data.form.description}</p>
              </>
            )}
          </div>
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className="top-row">
              <div>
                <label htmlFor="from_name">Name *</label>
                <input
                  type="text"
                  id="name"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="subject">Subject *</label>
              <select
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select a subject
                </option>
                {data &&
                  data?.form?.options &&
                  data?.form?.options.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="message-container">
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message *"
                required
                rows={width >= 768 ? 6 : 3}
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
          <div
            className={`message-status ${
              sendSuccess === "success" ? "show" : ""
            }`}
          >
            <p className="success-message">
              {sendSuccess === "success" &&
                "Thank you, your inquiry has been succesfully delivered. We will contact you shortly."}
              {sendSuccess === "error" &&
                "Sorry, there has been an error. Please email us directly at contact@sliceoflifebar.com"}
            </p>
          </div>
        </div>
        <div className="slider">
          {currentImage && (
            <img
              src={urlFor(currentImage.asset._ref).url()}
              alt="Slider Image"
            />
          )}
        </div>
      </section>
    </>
  );
};
