import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Hero = () => {
  const [data, setData] = useState([]);
  const [width] = useState(window.innerWidth);

  const builder = ImageUrlBuilder(sanityClient);
  const urlFor = (source) => {
    return builder.image(source);
  };

  const editUrlString = (urlString) => {
    let strUrl = urlString.replace(/file-/g, "");
    strUrl = strUrl.replace("-", ".");

    return `https://cdn.sanity.io/files/nebzpyuk/production/${strUrl}`;
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'hero'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="hero">
        {data && data.video && (
          <video width="320" height="240" loop muted autoPlay playsInline>
            <source
              src={editUrlString(data.video.asset._ref)}
              type="video/mp4"
            />
          </video>
        )}
        {data && data.content && (
          <div className="content">
            {data && data.logo && (
              <img src={urlFor(data.logo.asset._ref).url()} />
            )}
            <h1>{data.content.heading}</h1>
            <h3>{data.content.subHeading}</h3>
            <h4>{data.content.body}</h4>
            {width <= 768 && (
              <button className="reso-button">
                <a
                  href="https://www.opentable.ca/r/slice-of-life-reservations-toronto?restref=1321198&lang=en-CA&ot_source=Restaurant%20website"
                  target="_blank"
                >
                  reservations
                </a>
              </button>
            )}
          </div>
        )}
      </section>
    </>
  );
};
